// TermsAndConditions.jsx
import React from 'react';
import './termsandconditions.css'; 

const TermsAndConditions = () => {
    return (
        <div className="terms-conditions">
            <h1>Terms and Conditions - FreeInvoiceNow.com, The Premier Free Invoice Generator</h1>
<br/>

            <section>
                <h2>Understanding Our Terms</h2>
                <p>Welcome to FreeInvoiceNow.com, a leading free invoice generator designed for businesses and freelancers. By using our website, you are agreeing to these terms and conditions, which govern your use of our intuitive invoice generating platform.</p>
            </section>

            <section>
                <h2>Use of FreeInvoiceNow.com</h2>
                <p>FreeInvoiceNow.com offers a user-friendly and efficient way to create and manage invoices. Our platform is streamlined for ease of use, helping you to handle your invoicing needs with no special skills required.</p>
            </section>

            <section>
                <h2>Intellectual Property Rights</h2>
                <p>All content and materials on FreeInvoiceNow.com, including the design, graphics, and interface, are the exclusive property of FreeInvoiceNow.com. Unauthorized use of our materials is strictly prohibited.</p>
            </section>

            <section>
                <h2>Liability Limitation</h2>
                <p>While we strive for accuracy and reliability in our free invoice generator, FreeInvoiceNow.com is not liable for errors or inaccuracies that may arise. We are committed to continuously improving our services for your benefit.</p>
            </section>

            <section>
                <h2>Your Privacy With Us</h2>
                <p>Protecting your privacy is a priority at FreeInvoiceNow.com. We encourage you to review our Privacy Policy to understand how we safeguard the information you provide while using our services.</p>
            </section>

            <section>
                <h2>Terms Modifications</h2>
                <p>FreeInvoiceNow.com reserves the right to modify these terms. Continued use of our free invoice generator after changes signifies your acceptance of the new terms.</p>
            </section>

            <section>
                <h2>Get in Touch</h2>
                <p>Have questions about our terms? Reach out to us at <a href="mailto:support@freeinvoicenow.com">support@freeinvoicenow.com</a> for clarity and assistance.</p>
            </section>
        </div>
    );
};

export default TermsAndConditions;
