// PrivacyPolicy.jsx
import React from 'react';
import './privacypolicy.css'; 

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy - FreeInvoiceNow.com, The Ultimate Free Invoice Generator</h1>
            <br/>
            
            <section>
                <h2>About FreeInvoiceNow.com</h2>
                <p>Welcome to FreeInvoiceNow.com, the ultimate free invoice generator. We are dedicated to offering an efficient, user-friendly invoicing solution while prioritizing your privacy and security.</p>
            </section>

            <section>
                <h2>Our Commitment to Data Privacy</h2>
                <p>As the leading free invoice generator, FreeInvoiceNow.com understands the importance of data privacy. Here’s how we handle your information:</p>
                <h3>No Personal Data Collection</h3>
                <p>Our commitment to privacy means we do not collect personal information. FreeInvoiceNow.com enables you to generate invoices without storing personal data.</p>
                <h3>Local Data Storage</h3>
                <p>Your invoice data is stored locally on your device for your privacy and security. FreeInvoiceNow.com ensures that you have complete control over your data.</p>
            </section>

            <section>
                <h2>Your Privacy, Our Priority</h2>
                <p>We continuously work to enhance our privacy measures, ensuring that your experience with our free invoice generator remains secure and confidential.</p>
            </section>

            <section>
                <h2>Contact Us</h2>
                <p>If you have questions about our privacy practices, please contact us at <a href="mailto:support@freeinvoicenow.com">support@freeinvoicenow.com</a>. We are here to help!</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
