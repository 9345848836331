// Contact.jsx
import React from 'react';
import './contact.css'; 

const Contact = () => {
    return (
        <div className="contact-page">
            <h1>Contact FreeInvoiceNow.com - Free Invoice Generator</h1>
            <br/>
            <p>Looking for assistance with our free invoice generator? Have questions or feedback about FreeInvoiceNow.com? We're here to provide you with the support you need.</p>
            <p>For inquiries related to invoice generation, using our platform, or any general questions, please don't hesitate to reach out to us:</p>
            <p><a href="mailto:support@freeinvoicenow.com">support@freeinvoicenow.com</a></p>
        </div>
    );
};

export default Contact;
