// AboutUs.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './aboutus.css'; 

const AboutUs = () => {
    return (
        <div className="about-us">
            <h1>Welcome to FreeInvoiceNow.com – The Ultimate Free Invoice Generator</h1>
            
            <section>
                <h2>Our Journey</h2>
                <p>Recognizing the challenges of managing finances, we developed FreeInvoiceNow.com to offer a seamless, intuitive free invoice generator. We identified a gap in the market for a tool that was both user-friendly and cost-effective. Our platform is the result of our commitment to filling this gap.</p>
            </section>

            <section>
                <h2>What We Offer</h2>
                <p>FreeInvoiceNow.com is more than just a website; it's a comprehensive free invoice generator that provides:</p>
                <ul>
                    <li>Customizable invoice templates for various business needs.</li>
                    <li>Easy tracking and management of bills and payments.</li>
                    <li>A Progressive Web App (PWA) for accessible invoice management, anywhere and anytime.</li>
                </ul>
            </section>

            <section>
                <h2>Innovative Invoicing Through PWA</h2>
                <p>Our free invoice generator stands out by offering a Progressive Web App (PWA), ensuring that you have constant access to your financial documents, even offline. The PWA platform makes FreeInvoiceNow.com not only convenient but also adaptable to your busy lifestyle.</p>
            </section>

            <section>
                <h2>Dedicated to Your Success</h2>
                <p>We understand that trust and transparency are key. Thus, we are committed to offering a secure and straightforward free invoice generator. With FreeInvoiceNow.com, focus on growing your business, and let us handle the invoicing.</p>
            </section>

            <section>
                <h2>Join Our Community</h2>
                <p>Become part of a community that enjoys the simplicity and efficiency of our free invoice generator. Whether you're managing a small business or freelancing, we're here to support your financial management journey. Experience the ease of invoicing with FreeInvoiceNow.com today!</p>
            </section>

            <div className="contact-info">
                <p>Questions or feedback? Contact us at <a href="mailto:support@freeinvoicenow.com">support@freeinvoicenow.com</a> or visit our <Link to="/help">Help Center</Link> for more information.</p>
            </div>
        </div>
    );
}

export default AboutUs;
