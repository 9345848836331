// Footer.jsx
import React, { useState, useEffect } from 'react';
import './footer.css'; 
import { Link } from 'react-router-dom';

const Footer = () => {
    const getPreferredTheme = () => {
        const storedTheme = localStorage.getItem("theme");
        return storedTheme || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    };

    const [theme, setTheme] = useState(getPreferredTheme());

    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        const handler = (e) => setTheme(e.matches ? "dark" : "light");

        mediaQuery.addEventListener('change', handler);
        return () => mediaQuery.removeEventListener('change', handler);
    }, []);

    const footerClass = theme === 'dark' ? 'footer footer-dark' : 'footer';

    return (
        <footer className={footerClass}>
            <div className="container">
                <div className="footer-links">
                    <Link to="/about">About Us</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-and-conditions">Terms and Conditions</Link>
                    <Link to="/help">Help</Link>
                    <Link to="/contact">Contact</Link>
                </div>
                <div className="footer-copy">
                    <p>&copy; {new Date().getFullYear()} Free Invoice Generator - FreeInvoiceNow.Com. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
